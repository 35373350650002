/* eslint-disable react-hooks/exhaustive-deps */
import TopBar from "../../components/TopBar";
import TopContent from "./TopContent";

export default function Home() {
  return (
    <>
      <TopBar />
      <TopContent />
    </>
  );
}
