import { AppBar, Toolbar, Box } from "@mui/material";
import Logo from "../assets/logo.png";

export default function TopBar() {
  return (
    <Box
      sx={{
        flexGrow: 1,
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: { sm: "10px", xs: "2px" },
      }}
    >
      <AppBar
        position="static"
        display="flex"
        sx={{
          boxShadow: "none",
          maxWidth: "1400px",
          mx: "auto",
          backgroundColor: "transparent",
        }}
      >
        <Toolbar
          sx={{
            justifyContent: "space-between",
            height: { xs: "120px", md: "80px" },
          }}
        >
          <Box
            sx={{
              height: { xs: "48px", md: "40px" },
              width: { xs: "204px", md: "170px" },
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <img
              style={{
                width: "auto",
                height: "100%",
              }}
              alt=""
              src={Logo}
            />
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
