import * as React from "react";
import { Box, Typography } from "@mui/material";

import { goToAddress } from "../../utils";
import icon from "../../assets/icon.png";
import banner from "../../assets/banner.png";
import circle from "../../assets/circle.png";

export default function TopContent() {
  return (
    <Box
      sx={{
        maxWidth: { xs: "100%", md: "1285px" },
        mx: "auto",
        display: "flex",
        justifyContent: {
          xs: "space-between",
          md: "space-around",
          xl: "space-between",
        },
        flexDirection: { xs: "column", md: "row" },
        alignItems: { xs: "center", md: "flex-start" },
        py: { xs: "80px", md: "40px", xl: "80px" },
      }}
    >
      <Box
        sx={{
          width: { xs: "600px", xl: "682px" },
          height: { xs: "initial", xl: "680px" },
          pt: { xs: "initial", md: "50px" },
          pb: { xs: "150px", md: "initial" },
          display: "flex",
          flexDirection: "column",
          alignItems: { xs: "center", md: "initial" },
          position: "relative",
        }}
      >
        <Box
          sx={{
            height: { xs: "48px", md: "60px" },
            width: { xs: "48px", md: "60px" },
            alignItems: "center",
            position: { xs: "absolute", md: "fixed" },
            left: { xs: "0px", md: "30px", xl: "120px" },
            bottom: { xs: "80px", md: "30px", xl: "80px" },
          }}
        >
          <img
            style={{
              width: "auto",
              height: "100%",
            }}
            alt=""
            src={circle}
          />
        </Box>
        <Box
          sx={{
            height: "120px",
            width: "120px",
            display: { xs: "none", md: "flex" },
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <img
            style={{
              width: "auto",
              height: "100%",
            }}
            alt=""
            src={icon}
          />
        </Box>
        <Box sx={{ mt: "20px" }}>
          <Typography variant="h1">Carousel</Typography>
        </Box>
        <Box sx={{ mt: { xs: "20px", md: "0px", xl: "20px" } }}>
          <Typography variant="h2">Game aggregator</Typography>
        </Box>
        <Box sx={{ mt: { xs: "20px", md: "12px", xl: "20px" } }}>
          <Typography variant="body1">
            Playing most popular games in Carousel
          </Typography>
        </Box>
        <Box
          sx={{
            width: "290px",
            height: "65px",
            background: "linear-gradient(90deg, #2E6CFF 0%, #498FEC 100%)",
            borderRadius: "8px",
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: "white",
            mt: { xs: "60px", md: "30px", xl: "59px" },
          }}
          onClick={() => {
            goToAddress(
              "https://play.google.com/store/apps/details?id=com.game.aggregation.carousel"
            );
          }}
        >
          <Typography variant="body2">Start Free Trial</Typography>
        </Box>
      </Box>
      <Box
        sx={{
          width: { xs: "600px", md: "500px", xl: "682px" },
          height: { xs: "600px", md: "500px", xl: "680px" },
        }}
      >
        <img
          style={{
            width: "auto",
            height: "100%",
          }}
          alt=""
          src={banner}
        />
      </Box>
    </Box>
  );
}
