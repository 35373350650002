import { Box } from "@mui/material";

const Wrap = ({ sx, home, ...other }) => {
  return (
    <Box
      sx={{
        maxWidth: { xs: "100%", md: home ? "100%" : 1152 },
        margin: "0 auto",
        backgroundImage: "url(./topright.png)",
        backgroundRepeat: "no-repeat",
        backgroundSize: {
          xs: "176px 176px",
          md: "140px 140px",
          xl: "176px 176px",
        },
        backgroundPosition: "top right",
      }}
      {...other}
    ></Box>
  );
};

export default function Skeleton({ home, children }) {
  return (
    <Wrap id="wrapper" home={home}>
      {children}
    </Wrap>
  );
}
